import React, { useEffect } from "react";
import { Route, Switch, Link } from "react-router-dom";
import AccountsRouter from "traec-react/auth/router";
import NavBar from "traec-react/navBar";
import { ErrorBoundary } from "traec-react/errors";

import Traec from "traec";
import Home from "./home";
import { connect } from "react-redux";
import LoginRedirect from "./login-redirect";
import UserProfile from "AppSrc/user";
import AdminPage from "AppSrc/admin";
import GeoReport from "AppSrc/report/geo";
import SBCCReport from "AppSrc/report/sbcc";
import { ReportAdminPage } from "AppSrc/admin/report";
import CompanyProjectPage from "AppSrc/tree/mainPage";
import NavBarBrand from "./brand";
import CookieConsent from "react-cookie-consent";
import { TermsAndConditions, TermsAndConditionsRedirector } from "storybook-dashboard/contracts/terms";
import SupportDropdown from "./support.js"
import { getAzureConfig } from "./azure.js";
import useToken from "storybook-dashboard/auth/token";

// Route-based code splitting
// https://reactjs.org/docs/code-splitting.html
//const Company = React.lazy(() => import(/* webpackChunkName: "company" */ './company'))
//const Project = React.lazy(() => import(/* webpackChunkName: "project" */'./project'))

export const getCreateText = () => {
  let is_sbcc = location.hostname.startsWith("sbcc") || location.hostname.includes("localhost");
  return is_sbcc ? "Create an account" : "Create a supplier account";
};

function jiraBugCollectorFieldValues() {
  //console.log("Getting field values for support form submission");
  var values = {
    //fullname: "User name",
    //email: "user@my.domain",
    recordWebInfo: "1", // field Name
    recordWebInfoConsent: ["1"], // field Id
  };
  return values;
}

window.ATL_JQ_PAGE_PROPS = {
  triggerFunction: function (showCollectorDialog) {
    jQuery("#jira-bug-collector").click(function (e) {
      e.preventDefault();
      showCollectorDialog();
    });
  },
  fieldValues: jiraBugCollectorFieldValues,
};



function MainSwitch(props) {
  //console.log("REDIRECT AT MAINSWITCH", props);
  return (
    <Switch>
      {/* there will only ever be one child in the Switch */}
      <Route exact path="/" component={Home} />

      {/* Terms and conditions page */}
      <Route exact path="/terms" component={TermsAndConditions} />

      {/* Registration and email activation */}
      <Route
        path="/accounts"
        render={(routeProps) => (
          <AccountsRouter
            {...routeProps}
            UserProfile={UserProfile}
            createText={getCreateText()}
            metaFieldProps={[{ name: "company_name", placeholder: "Company Name" }]}
            azureConfig={getAzureConfig()}
          />
        )}
      />

      {/* Route to a Company Dashboard */}
      <Route path="/company/:_companyId" component={CompanyProjectPage} />

      {/* Route to a Project or WorkPackage Dashboard */}
      <Route path="/project/:_projectId/wpack/:_refId" component={CompanyProjectPage} />
      <Route path="/project/:_projectId" component={CompanyProjectPage} />

      {/* Router to the admin path */}
      <Route path="/tenant/admin/" component={AdminPage} />
      <Route path="/tenant/reports/" component={ReportAdminPage} />

      {/* Pages for reporting */}
      <Route exact path={`/report/geo`} component={GeoReport} />
      <Route exact path={`/report/sbcc/:_type/:_typeId`} component={SBCCReport} />

      {/* Default render homepage if no path matched */}
      <Route exact path={`/home`} component={Home} />
      <Route component={Home} />
    </Switch>
  );
}


function CookieConsentWrapper({hide}) {
  if (hide) { return null }
  return (
    <CookieConsent
      contentStyle={{ margin: "10px 0px 10px 10px" }}
      disableStyles={true}
      style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
      buttonClasses="btn btn-primary"
      containerClasses="alert alert-warning col-lg-12"
      buttonText="OK"
      expires={1}
    >
      <p className="m-0">
        We are making some important updates which may impact your experience using the system at this time. Do not
        hesitate to{" "}
        <a
          className="cookie-email"
          style={{ color: "black" }}
          href="mailto:info@actionsustainability.com?subject=Impacted by updates&body=Please describe your issue and when you experienced it."
          target="_blank"
        >
          contact us
        </a>{" "}
        so we can ensure there is no disruption to your business reporting as usual. Thank you for your continued
        support and patience.
      </p>
    </CookieConsent>
  )
}


export function _AppRouter(props) {
  let { token } = props

  useEffect(() => {
    Traec.fetchRequiredFor({
      props,
      requiredFetches: [new Traec.Fetch("version", "read"), new Traec.Fetch("tenant_meta", "read")],
    });
  });

  return (
    <ErrorBoundary title="Error this page">
      <LoginRedirect>
        <TermsAndConditionsRedirector />

        <NavBar
          brand={<NavBarBrand />}
          preUserItems={<SupportDropdown />}
          include_myprofile={false}
          includeUser={true}
          getUserItemLabel={(user) => user.get("email")}
          userLabel={"My account"}
          createText={getCreateText()}
          azureConfig={getAzureConfig()}
        />
        <CookieConsentWrapper hide={true} />
        <MainSwitch />
      </LoginRedirect>
    </ErrorBoundary>
  );
}


const mapStateToProps = (state, ownProps) => ({ token: state.getInPath("auth.token") });
export const AppRouter = connect(mapStateToProps)(_AppRouter);
